import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HolNotification } from 'src/app/common/models/hol-notification.model';
import { NotificationsService } from 'src/app/common/services/notifications/notifications.service';
import { RequestService } from 'src/app/common/services/request.service';
import { UserService } from 'src/app/common/services/user.service';
import { EclAnnouncement } from '../models/ecl-announcement';
import { EclCrisisStoreManager } from '../store/crisis/crisis.store-manager';
import { EclHistoryService } from './ecl-history-service/ecl-history.service';
import { EclMailService } from './ecl-mail-service/ecl-mail.service';
import { EclSmsService } from './ecl-sms-service/ecl-sms.service';
import { take } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class EclAnnouncementService {
  // tslint:disable:variable-name
  ParseCrisis = Parse.Object.extend('ECLCrisis');
  ParseCrisisAnnouncement = Parse.Object.extend('ECLAnnouncement');
  ParseGDCTag = Parse.Object.extend('ECLTag');
  ParseUser = Parse.Object.extend('_User');
  ParseLogbook = Parse.Object.extend('ECLLogBook');

  // tslint:enabled

  constructor(
    private userService: UserService,
    private eclCrisisStoreManager: EclCrisisStoreManager,
    private requestService: RequestService,
    private notificationsService: NotificationsService,
    private eclMailService: EclMailService,
    private eclSmsService: EclSmsService,
    private historyService: EclHistoryService,
  ) {}

  getValue(obj: Observable<any>) {
    let value: any;
    obj.subscribe(v => (value = v));
    return value;
  }

  getAnnouncementByObjectId(objectId: string): Promise<EclAnnouncement> {
    let announcement: EclAnnouncement;
    return new Promise((resolve, reject) => {
      const announcementQuery = new Parse.Query(this.ParseCrisisAnnouncement);
      announcementQuery.equalTo('objectId', objectId);
      this.requestService
        .performFirstQuery(
          announcementQuery,
          announcementFromApi => {
            if (announcementFromApi) {
              announcement = new EclAnnouncement(announcementFromApi);
              resolve(announcement);
            } else {
              resolve(undefined);
            }
          },
          reject,
        )
        .then();
    });
  }

  getCrisisAnnouncementByCrisisObjectId(crisisObjectId): Promise<EclAnnouncement[]> {
    let crisisAnnouncements: EclAnnouncement[];
    return new Promise((resolve, reject) => {
      const crisis = new this.ParseCrisis();
      crisis.set('objectId', crisisObjectId);
      const crisisAnnouncementQuery = new Parse.Query(this.ParseCrisisAnnouncement);
      crisisAnnouncementQuery.include('ACL');
      crisisAnnouncementQuery.descending('createdAt');
      crisisAnnouncementQuery.equalTo('crisis', crisis);
      crisisAnnouncementQuery.include('createdBy');
      crisisAnnouncementQuery.include('tags');
      this.requestService
        .performFindQuery(
          crisisAnnouncementQuery,
          crisisAnnouncementsFromApi => {
            if (crisisAnnouncementsFromApi) {
              crisisAnnouncements = crisisAnnouncementsFromApi.map(
                crisisAnnouncementFromApi => new EclAnnouncement(crisisAnnouncementFromApi),
              );
              resolve(crisisAnnouncements);
            } else {
              resolve([]);
            }
          },
          reject,
        )
        .then();
    });
  }

  // tslint:disable-next-line: max-line-length
  saveCrisisAnnouncement(
    crisisAnnouncement: Partial<EclAnnouncement>,
    crisisObjectId?: string,
    notifications: HolNotification[] = [],
    historyLogComment?: string,
    isArchiveAction: boolean = false,
    logbookObjectId?: string,
  ): void {
    let parseObject;
    let isCreate = false;
    if (crisisAnnouncement.objectId) {
      parseObject = new this.ParseCrisisAnnouncement({ id: crisisAnnouncement.objectId });
    } else {
      isCreate = true;
      parseObject = new this.ParseCrisisAnnouncement();
      parseObject.set('createdBy', new this.ParseUser({ id: this.userService.getCurrentUserObject().objectId }));
      parseObject.set('crisis', new this.ParseCrisis({ id: crisisObjectId }));
      parseObject.set('createdByFunctionId', crisisAnnouncement.createdByFunctionId);
    }
    if (crisisAnnouncement.acl) {
      parseObject.setACL(crisisAnnouncement.acl);
    }
    if (crisisAnnouncement.message) {
      parseObject.set('message', crisisAnnouncement.message);
    }

    if (crisisAnnouncement.visibilityByFunctionId) {
      parseObject.set('visibilityByFunctionId', crisisAnnouncement.visibilityByFunctionId);
    }

    if (crisisAnnouncement.attachments) {
      parseObject.set('attachments', crisisAnnouncement.attachments);
    }

    if (crisisAnnouncement.tags && crisisAnnouncement.tags.length) {
      const parseTags = crisisAnnouncement.tags.map(tag => new this.ParseGDCTag({ id: tag.objectId }));
      parseObject.set('tags', parseTags);
    } else {
      parseObject.set('tags', []);
    }

    if (isArchiveAction) {
      parseObject.set('isArchived', true);
    }

    if (crisisAnnouncement.customCreatedAt) {
      parseObject.set('customCreatedAt', crisisAnnouncement.customCreatedAt);
    }

    if (logbookObjectId) {
      parseObject.set('transferToLogbook', new this.ParseLogbook({ id: logbookObjectId }));
    }

    let bufferAnnouncementReply: EclAnnouncement | undefined = undefined;
    if (crisisAnnouncement.replyToAnnouncementObjectId) {
      const parseAnnouncementReply = new this.ParseCrisisAnnouncement({ id: crisisAnnouncement.replyToAnnouncementObjectId });
      parseObject.set('replyTo', parseAnnouncementReply);
      bufferAnnouncementReply = new EclAnnouncement(parseAnnouncementReply);
    }

    this.requestService.performSaveQuery(
      parseObject,
      null,
      parseData => {
        const bufferCrisisAnnouncement: EclAnnouncement = new EclAnnouncement(parseData);
        if (isCreate) {
          if (notifications && notifications.length) {
            const addressMailToSend: string[] = this.notificationsService.getAddressMailToSend(notifications);
            const phoneNumbersToSend: string[] = this.notificationsService.getPhoneNumbersToSend(notifications);

            if (addressMailToSend && addressMailToSend.length) {
              this.eclMailService.sendECLAnnouncementMail(
                bufferCrisisAnnouncement,
                bufferAnnouncementReply,
                this.userService.getCurrentUserObject(),
                addressMailToSend,
              );
            }

            if (phoneNumbersToSend && phoneNumbersToSend.length) {
              this.eclSmsService.sendCrisisAnnouncementSms(
                bufferCrisisAnnouncement,
                bufferAnnouncementReply,
                this.userService.getCurrentUserObject(),
                phoneNumbersToSend,
              );
            }
          }
          this.eclCrisisStoreManager.createOneAnnouncement(bufferCrisisAnnouncement);
          this.historyService
            .postLog(bufferCrisisAnnouncement.toLog(historyLogComment, parseData, crisisObjectId))
            .catch(error => console.log(error));
          return;
        }
        this.eclCrisisStoreManager.upadteOneAnnouncement(bufferCrisisAnnouncement);
        this.historyService
          .postLog(bufferCrisisAnnouncement.toLog(historyLogComment, parseData, crisisObjectId))
          .catch(error => console.log(error));
      },
      error => {
        console.log(error);
      },
    );
  }

  deleteAllAnnouncements(): Promise<Parse.Object[]> {
    const query = new Parse.Query(this.ParseCrisisAnnouncement);

    return this.requestService.performFindQuery(query).then(results => {
      return this.requestService.performDestroyAllQuery(results);
    });
  }

  async fetchNewData(): Promise<void> {
    this.eclCrisisStoreManager.$eclSelectedCrisis.pipe(take(1)).subscribe(async selectedCrisis => {
      if (selectedCrisis) {
        const buffer = await this.getCrisisAnnouncementByCrisisObjectId(selectedCrisis.objectId);
        this.eclCrisisStoreManager.initEclAnnouncementListFromDashboardPolling(buffer);
      }
    });
  }

  public announcementReaded(announcement: EclAnnouncement): Promise<void> {
    const parseAnnouncement = new this.ParseCrisisAnnouncement({ id: announcement.objectId });
    parseAnnouncement.set('readed', true);
    return this.requestService.performSaveQuery(parseAnnouncement);
  }
}
