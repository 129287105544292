import { Inject, Injectable } from '@angular/core';
import moment from 'moment';
import { MailSenderService } from 'src/app/common/services/mail/mail-sender.service';
import { MailTemplateService } from 'src/app/common/services/mail/mail-template.service';
import { OclMailService } from '../../../ocl/services/ocl-mail-service/ocl-mail.service';
import { EclAnnouncement } from '../../models/ecl-announcement';
import { EclModuleConfigService } from '../ecl-module-config/ecl-module-config.service';
import { EclCrisis } from '../../models/ecl-crisis';
import { HolUser, HolUserWithCompanies } from '../../../common/models/hol-user.model';
import { take } from 'rxjs/operators';
import { CommonStoreManager } from '../../../common/store/common.store-manager';
import { HolUserWithFunctions } from '../../../erp/services/erp-users.service';
import { EclUsersService } from '../ecl-users-service/ecl-users.service';
import { map } from 'lodash';
import { EclCrisisDirectorStoreManager } from '../../store/crisis-director/ecl-crisis-director.store-manager';
import { mergeDirectorsAndUsers } from '../../common/user-utils';
import { EclFunction, EclOwnerNotificationData } from '../../models/ecl-function';

@Injectable({
  providedIn: 'root',
})
export class EclMailService extends OclMailService {
  constructor(
    protected readonly mailSenderService: MailSenderService,
    @Inject('CONSTANTS') protected CONSTANTS,
    protected mailTemplateService: MailTemplateService,
    protected moduleConfig: EclModuleConfigService,
    private commonStoreManager: CommonStoreManager,
    private usersService: EclUsersService,
    private eclCrisisDirectorStoreManager: EclCrisisDirectorStoreManager,
  ) {
    super(mailSenderService, CONSTANTS, mailTemplateService, moduleConfig);
    this.translate.onLangChange.subscribe((): void => {
      this.SENDER_SUFFIX = this.translate.instant('MAIL.ECL.SENDER_SUFFIX') || 'ECL';
    });
  }

  sendECLAnnouncementMail(
    eclAnnouncement: EclAnnouncement,
    announcementReply: EclAnnouncement | undefined,
    user: any,
    mailsToSend: string[],
  ): void {
    if (!eclAnnouncement) {
      console.warn('EclMailService::sendECLAnnouncement: crisisAnnouncement empty');
    }
    if (!mailsToSend || !mailsToSend.length) {
      console.warn('EclMailService::sendECLAnnouncement: no mailing list found');
    }

    this.mailSenderService
      .sendMail(
        {
          recipients: map(mailsToSend, (m: string): { email: string } => {
            return { email: m };
          }),
          subject: announcementReply
            ? this.translate.instant('MAIL.ECL.NEW_ANNOUNCEMENT.SUBJECT_REPLY', { eclAnnouncement, user })
            : this.translate.instant('MAIL.ECL.NEW_ANNOUNCEMENT.SUBJECT', { eclAnnouncement, user }),
          contentHtml: this.getECLsAnnouncementMail(eclAnnouncement, announcementReply),
          sender: this.CONSTANTS.COMPANY_NAME + this.CONSTANTS.CRISIS_SUFFIX,
        },
        true,
        true,
      )
      .then();
  }

  async sendNewCrisisEmails(crisis: EclCrisis, userToNotifyFromUser: string[]): Promise<void> {
    // We're sending emails for people:
    // 1. Who has crisis director rights
    // OR
    // 2. Whose functions are included in crisis.crisisType.functionsToNotify
    return Promise.all([
      this.eclCrisisDirectorStoreManager.$eclRealCrisisDirectorsWithFunctions().pipe(take(1)).toPromise(),
      this.commonStoreManager.currentUser.pipe(take(1)).toPromise(),
      this.usersService.getUsersWithFunctionsForCrisis(crisis),
    ]).then(([crisisDirectors, currentUser, usersToNotify]: [HolUserWithFunctions[], HolUser, HolUserWithFunctions[]]): void => {
      let users: HolUserWithFunctions[] = mergeDirectorsAndUsers(crisisDirectors, usersToNotify);
      if (userToNotifyFromUser) {
        users = users.filter((us: HolUserWithFunctions): boolean => userToNotifyFromUser.includes(us.userId));
      }
      for (const user of users) {
        this.sendNewCrisisEmailToUser(crisis, user, currentUser);
      }
    });
  }

  async sendCloseCrisisMail(crisis: EclCrisis): Promise<void> {
    // We're sending emails for people:
    // 1. Who has crisis director rights
    // OR
    // 2. Whose functions are included in crisis.crisisType.functionsToNotify
    const [directorsToNotify, usersToNotify] = await Promise.all([
      this.eclCrisisDirectorStoreManager.$eclRealCrisisDirectorsWithFunctions().pipe(take(1)).toPromise(),
      this.usersService.getUsersWithFunctionsForCrisis(crisis),
    ]);

    await this.mailSenderService.sendMail(
      {
        recipients: mergeDirectorsAndUsers(directorsToNotify, usersToNotify).map((user: HolUserWithFunctions): { email: string } => {
          return { email: user.email };
        }),
        subject: this.translate.instant('MAIL.ECL.CRISIS.CRISIS_OVER.SUBJECT', { crisisMainTitle: crisis.mainTitle }),
        contentText: this.translate.instant('MAIL.ECL.CRISIS.CRISIS_OVER.CONTENT_TEXT'),
        sender: this.CONSTANTS.COMPANY_NAME + this.CONSTANTS.CRISIS_SUFFIX,
      },
      false,
      true,
    );
  }

  async sendMailAddMemberOfFunction(user: HolUser, crisis: EclCrisis, newFunction: EclFunction): Promise<void> {
    const [directorsToNotify, usersToNotify, currentUser] = await Promise.all([
      this.eclCrisisDirectorStoreManager.$eclRealCrisisDirectorsWithFunctions().pipe(take(1)).toPromise(),
      this.usersService.getUsersWithFunctionsForCrisis(crisis),
      this.commonStoreManager.currentUser.pipe(take(1)).toPromise(),
    ]);

    let msg: string = this.translate.instant('MAIL.ECL.CRISIS.MEMBER_ADD.CONTENT_TEXT', {
      functionName: newFunction.title,
      user: currentUser.fullName,
    });

    msg += this.translate.instant('MAIL.ECL.CRISIS.PLEASE_LOGIN', { appUrl: location.origin });

    await this.mailSenderService.sendMail(
      {
        recipients: usersToNotify.filter((value: HolUserWithFunctions): boolean => {
          return value.userId == user.userId;
        }),
        subject: this.translate.instant('MAIL.ECL.CRISIS.MEMBER_ADD.SUBJECT', { crisisMainTitle: crisis.mainTitle }),
        contentText: msg,
        sender: this.CONSTANTS.COMPANY_NAME + this.CONSTANTS.CRISIS_SUFFIX,
      },
      false,
      true,
    );
  }

  async sendAcessDone(user: HolUser, crisis: EclCrisis): Promise<void> {
    const [directorsToNotify, usersToNotify, currentUser] = await Promise.all([
      this.eclCrisisDirectorStoreManager.$eclRealCrisisDirectorsWithFunctions().pipe(take(1)).toPromise(),
      this.usersService.getUsersWithFunctionsForCrisis(crisis),
      this.commonStoreManager.currentUser.pipe(take(1)).toPromise(),
    ]);

    let msg: string = this.translate.instant('MAIL.ECL.CRISIS.MEMBER_REMOVE.CONTENT_TEXT', {
      crisisName: crisis.mainTitle,
    });

    msg += this.translate.instant('MAIL.ECL.CRISIS.PLEASE_LOGIN', { appUrl: location.origin });

    await this.mailSenderService.sendMail(
      {
        recipients: usersToNotify.filter((value: HolUserWithFunctions): boolean => {
          return value.userId == user.userId;
        }),
        subject: this.translate.instant('MAIL.ECL.CRISIS.MEMBER_REMOVE.SUBJECT', { crisisMainTitle: crisis.mainTitle }),
        contentText: msg,
        sender: this.CONSTANTS.COMPANY_NAME + this.CONSTANTS.CRISIS_SUFFIX,
      },
      false,
      true,
    );
  }

  async sendRepoenCrisisEmails(crisis: EclCrisis): Promise<void> {
    return Promise.all([
      this.eclCrisisDirectorStoreManager.$eclRealCrisisDirectorsWithFunctions().pipe(take(1)).toPromise(),
      this.commonStoreManager.currentUser.pipe(take(1)).toPromise(),
      this.usersService.getUsersWithFunctionsForCrisis(crisis),
    ]).then(([crisisDirectors, currentUser, usersToNotify]: [HolUserWithFunctions[], HolUser, HolUserWithFunctions[]]): void => {
      const users: HolUserWithFunctions[] = mergeDirectorsAndUsers(crisisDirectors, usersToNotify);
      for (const user of users) {
        this.sendReopenCrisisEmailToUser(crisis, user, currentUser);
      }
    });
  }

  private getECLsAnnouncementMail(eclAnnouncement: EclAnnouncement, announcementReply: EclAnnouncement | undefined): string {
    let msg = '';
    if (announcementReply) {
      msg += '<table style="border: none; width:100%!important;" cellpadding="5px" cellspacing="0" width="100%"><tr>';
      msg += '<td> </td></tr><tr>';
      msg += '<td>' + this.translate.instant('MAIL.ECL.NEW_ANNOUNCEMENT.ORIGIN_MESSAGE') + '</td>';
      msg +=
        '</tr></table><table style="border: 1px solid #E7E7E7; width:100%!important;" cellpadding="5px" cellspacing="0" width="100%"><tr>';
      msg +=
        '<td width="1px" style="white-space: nowrap">' +
        moment(announcementReply.createdAt).utc().format('HH:mm[UTC]') +
        '<br/>' +
        moment(announcementReply.createdAt).utc().format('ddd[&nbsp;]DD') +
        '</td>';
      msg += '<td>' + announcementReply.message + '</td>';
      msg += '</tr></table><table style="border: none; width:100%!important;" cellpadding="5px" cellspacing="0" width="100%"><tr>';
      msg += '<td> </td></tr><tr>';
      msg += '<td>' + this.translate.instant('MAIL.ECL.NEW_ANNOUNCEMENT.REPLYED_MESSAGE') + '</td>';
      msg += '</tr></table>';
    }
    msg += '<table style="border: 1px solid #E7E7E7; width:100%!important;" cellpadding="5px" cellspacing="0" width="100%"><tr>';
    msg +=
      '<td width="1px" style="white-space: nowrap">' +
      moment(eclAnnouncement.createdAt).utc().format('HH:mm[UTC]') +
      '<br/>' +
      moment(eclAnnouncement.createdAt).utc().format('ddd[&nbsp;]DD') +
      '</td>';
    msg += '<td>' + eclAnnouncement.message + '</td>';
    if (
      eclAnnouncement.attachments &&
      (eclAnnouncement.attachments.note ||
        eclAnnouncement.attachments.noteFile ||
        eclAnnouncement.attachments.file ||
        eclAnnouncement.attachments.image)
    ) {
      msg += '<td width="1" style="white-space: nowrap">';
      if (eclAnnouncement.attachments.noteFile) {
        msg +=
          '<a href="' +
          eclAnnouncement.attachments.noteFile.url +
          '" target="_blank" title="' +
          eclAnnouncement.attachments.noteFile.fileName +
          '">📝</a>&nbsp;';
      } else if (eclAnnouncement.attachments.note) {
        msg += '<span title="' + eclAnnouncement.attachments.note + '">📝</span>&nbsp;';
      }
      if (eclAnnouncement.attachments.file) {
        msg +=
          '<a title="' + eclAnnouncement.attachments.file.fileName + '" href="' + eclAnnouncement.attachments.file.url + '">📎</a>&nbsp;';
      }
      if (eclAnnouncement.attachments.image) {
        msg +=
          '<a title="' + eclAnnouncement.attachments.image.fileName + '" href="' + eclAnnouncement.attachments.image.url + '">🖼</a>&nbsp;';
      }
      msg += '</td>';
    }

    msg += '</tr></table>';

    return msg;
  }

  private sendNewCrisisEmailToUser(crisis: EclCrisis, user: HolUserWithFunctions, crisisCreator: HolUser): void {
    const msg: string = this.getNewCrisisMessage(crisis, user, crisisCreator);
    const subject: string = crisis.isTraining
      ? this.translate.instant('MAIL.CRISIS.EXERCICE_HEADER', { crisisMainTitle: crisis.mainTitle })
      : this.translate.instant('MAIL.CRISIS.NOT_EXERCICE_HEADER', { crisisMainTitle: crisis.mainTitle });
    this.mailSenderService
      .sendMail(
        {
          recipients: [{ email: user.email }],
          subject,
          contentHtml: msg,
          sender: this.CONSTANTS.COMPANY_NAME + this.CONSTANTS.CRISIS_SUFFIX,
        },
        false,
        true,
      )
      .then();
  }

  private sendReopenCrisisEmailToUser(crisis: EclCrisis, user: HolUserWithFunctions, crisisCreator: HolUser): void {
    const msg: string = this.getNewCrisisMessage(crisis, user, crisisCreator, true);
    const subject: string = crisis.isTraining
      ? this.translate.instant('MAIL.CRISIS.EXERCICE_HEADER', { crisisMainTitle: crisis.mainTitle })
      : this.translate.instant('MAIL.CRISIS.NOT_EXERCICE_HEADER', { crisisMainTitle: crisis.mainTitle });
    this.mailSenderService
      .sendMail(
        {
          recipients: [{ email: user.email }],
          subject,
          contentHtml: msg,
          sender: this.CONSTANTS.COMPANY_NAME + this.CONSTANTS.CRISIS_SUFFIX,
        },
        false,
        true,
      )
      .then();
  }

  private getNewCrisisMessage(crisis: EclCrisis, user: HolUserWithFunctions, crisisCreator: HolUser, reOpen = false): string {
    let msg: string = this.translate.instant(reOpen ? 'MAIL.ECL.CRISIS.CRISIS_REOPEN' : 'MAIL.ECL.CRISIS.CRISIS_CREATED') + '<br/><br/>';

    msg += this.translate.instant(reOpen ? 'MAIL.ECL.CRISIS.REOPEN_CRISIS' : 'MAIL.ECL.CRISIS.NEW_CRISIS_CREATED', {
      crisisCreatorName: crisisCreator.fullName,
      crisisMainTitle: crisis.mainTitle,
      crisisSubtitle: crisis.subTitle,
    });
    if (user.functions.length) {
      for (const func of user.functions) {
        try {
          msg += this.translate.instant('MAIL.ECL.CRISIS.MEMBER_OF', {
            function: func.title,
            company: func.company,
          });
        } catch (error) {
          console.warn('Invalid taskSummary for function ' + func.title + ': ', func.tasksSummary);
        }

        if (func.otherUsers.length > 0) {
          msg += this.translate.instant('MAIL.ECL.CRISIS.COORDINATION') + '<ul>';
          for (const u of func.otherUsers) {
            msg += `<li>${u}</li>`;
          }
          msg += '</ul>';
        } else {
          msg += '<br/>';
        }

        msg += '<hr/>';
      }
    } else {
      console.warn('no function for user ', user);
    }

    msg += this.translate.instant('MAIL.ECL.CRISIS.PLEASE_LOGIN', { appUrl: location.origin }) + '<br/><br/>';

    msg +=
      this.translate.instant('MAIL.ECL.CRISIS.CONTACT_CREATOR', {
        crisisCreatorName: crisisCreator.fullName,
        crisisCreatorPhone: crisisCreator.phone || '',
        crisisCreatorEmail: crisisCreator.email || '',
      }) + '<br/><br/>';

    msg += this.translate.instant('MAIL.ECL.CRISIS.ECL_MARKETING') + '<br/><br/>';

    msg += this.translate.instant('MAIL.ECL.CRISIS.USE_IT');

    return msg;
  }

  sendErpActivateCrisisEmails(crisis: EclCrisis, usersToNotifyByUser: HolUserWithFunctions[]): void {
    const msg: string =
      this.translate.instant('MAIL.ECL.CRISIS.ERP_ADD.CONTENT_TEXT', { crisisMainTitle: crisis.mainTitle }) + '<br/><br/>';

    this.mailSenderService
      .sendMail(
        {
          recipients: usersToNotifyByUser,
          subject: this.translate.instant('MAIL.ECL.CRISIS.ERP_ADD.SUBJECT', { crisisMainTitle: crisis.mainTitle }),
          contentText: msg,
          sender: this.CONSTANTS.COMPANY_NAME + this.CONSTANTS.CRISIS_SUFFIX,
        },
        false,
        true,
      )
      .then();
  }

  sendNewOwner(data: EclOwnerNotificationData, onlyOwner: boolean): void {
    const separator = '<br>';
    let msg: string =
      (data.eclCrisis.isTraining
        ? this.translate.instant('MAIL.ECL.CRISIS.EXERCICE_HEADER', { crisisMainTitle: data.eclCrisis.mainTitle })
        : this.translate.instant('MAIL.ECL.CRISIS.NOT_EXERCICE_HEADER', { crisisMainTitle: data.eclCrisis.mainTitle })) +
      separator +
      separator;

    let msgAll: string = msg;

    msg +=
      this.translate.instant('MAIL.ECL.OWNER_ADD.YOUR_OWNER', {
        functionName: data.functionName,
        acl: data.acl,
      }) +
      separator +
      separator;
    msgAll +=
      this.translate.instant('MAIL.ECL.OWNER_ADD.THIS_OWNER', {
        functionName: data.functionName,
        acl: data.acl,
        userName: data.owner.fullName,
      }) +
      separator +
      separator;

    if (onlyOwner) {
      msg += this.translate.instant('MAIL.ECL.OWNER_ADD.ONLY_OWNER_NOTIF') + separator + separator;
    } else {
      msg += this.translate.instant('MAIL.ECL.OWNER_ADD.ALL_NOTIF') + separator;
      msgAll += this.translate.instant('MAIL.ECL.OWNER_ADD.ALL_NOTIF') + separator;
      for (const user of data.users) {
        if (user.phone) {
          msg += user.fullName + separator;
          msgAll += user.fullName + separator;
        }
      }
      msg += separator + separator;
    }

    const userOtherToNotify: HolUserWithCompanies[] = data.users.filter((u: HolUserWithCompanies): string => u.email);

    if (data.owner.email) {
      this.mailSenderService
        .sendMail(
          {
            recipients: [data.owner],
            subject: this.translate.instant('MAIL.ECL.OWNER_ADD.SUBJECT', { crisisMainTitle: data.eclCrisis.mainTitle }),
            contentText: msg,
            sender: this.CONSTANTS.COMPANY_NAME + this.CONSTANTS.CRISIS_SUFFIX,
          },
          false,
          true,
        )
        .then();
    }
    if (!onlyOwner) {
      if (userOtherToNotify.length) {
        this.mailSenderService
          .sendMail(
            {
              recipients: userOtherToNotify,
              subject: this.translate.instant('MAIL.ECL.OWNER_ADD.SUBJECT', { crisisMainTitle: data.eclCrisis.mainTitle }),
              contentText: msgAll,
              sender: this.CONSTANTS.COMPANY_NAME + this.CONSTANTS.CRISIS_SUFFIX,
            },
            false,
            true,
          )
          .then();
      }
    }
  }
}
