import { Injectable } from '@angular/core';
import { from, Subscription, combineLatest } from 'rxjs';
import { NotificationsService } from 'src/app/common/services/notifications/notifications.service';
import { RequestService } from 'src/app/common/services/request.service';
import { UserService } from 'src/app/common/services/user.service';
import { CrisisStoreManager } from '../../erp/store/crisis/crisis.store-manager';
import { EclFunctionStoreManager } from '../../ecl/store/function/function.store-manager';
import { ErpFunctionsService } from '../../erp/services/erp-functions.service';
import { ErpCrisisAnnouncementService } from '../../erp/services/erp-crisis-announcement.service';
import { EclAnnouncementService } from '../../ecl/services/ecl-announcement.service';
import { EclCrisisService } from '../../ecl/services/ecl-crisis-service/ecl-crisis.service';

@Injectable({
  providedIn: 'root',
})
export class HolAnnouncementService {
  private msgDataSub: Subscription;

  constructor(
    private userService: UserService,
    private crisisStoreManager: CrisisStoreManager,
    private requestService: RequestService,
    private notificationsService: NotificationsService,
    private eclFunctionStoreManager: EclFunctionStoreManager,
    private erpFunctionsService: ErpFunctionsService,
    private erpCrisisAnnouncementService: ErpCrisisAnnouncementService,
    private eclAnnouncementService: EclAnnouncementService,
    private eclCrisisService: EclCrisisService,
  ) {}

  async getAnnouncementData(module): Promise<any[]> {
    let result: any[];
    const ECLMessageCrisis = this.eclCrisisService.getCurrentOrLastCrisis();
    const CrisisObs = from(ECLMessageCrisis);
    const userEclFunctionsObs = this.eclFunctionStoreManager.$userFunctions;
    const userErpFunctions = this.userService.getCurrentUserFunctions();
    const userErpFunctionsObs = from(userErpFunctions);
    // const userObs = this.userService.getCurrentUserObject();
    if (module === 'ECL') {
      this.msgDataSub = combineLatest([CrisisObs, userEclFunctionsObs]).subscribe(val => {
        result = val;
        // return val;
      });
    }
    if (module === 'ERP') {
      this.msgDataSub = combineLatest([CrisisObs, userErpFunctionsObs]).subscribe(val => {
        result = val;
        // return val;
      });
    }
    return result;
  }

  private normalizeWords(words) {
    return words.normalize('NFD').replace(/[\u0300-\u036f]/g, '');
  }

  private formatUser(user) {
    const search = / /gi;
    const concatFirstName = user.firstName.replace(search, '_');
    const concatLastName = user.lastName.replace(search, '_');
    return this.normalizeWords('@' + concatFirstName + '__' + concatLastName);
  }

  private includesUserFunction(msgFunct, userFunct) {
    let included = false;
    if (msgFunct) {
      msgFunct.forEach(f => {
        f = f.slice(1);
        if (userFunct.includes(f)) {
          included = true;
        }
      });
    }
    return included;
  }
}
